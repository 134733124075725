/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
	margin: 0;
	background-color: #fafafa;
}

a {
	text-decoration: none;
	color: #E94E77;
}

.App {
	font-family: 'Roboto', sans-serif;
}

.Page {
	position: relative;
	z-index: 3;
	max-width: 600px;
	font-size: 1.0em;
	padding: 0;
	margin: 0 auto;
	/* font-family: 'Montserrat', cursive; */
	/* font-family: 'Syncopate', cursive; */
	font-family: 'Amatic SC', cursive;
	font-size: 1.5em;
}

.Page h1 {
	font-family: 'Monoton', cursive;
}